body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-alert-message {
  font-weight: 600 !important;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(156, 12, 12, 0.3);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }
}
.pulse {
  border-radius: 0.28571429rem;
  animation: shadow-pulse 1s infinite;
}
.text-muted {
  color: #72849ab3;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.8s;
  opacity: 1;
}

/*
Consent Cookie overrides
*/
.cc-revoke,
.cc-window {
  font-size: 12px !important;
}
.cc-window.cc-banner {
  padding: 0.85em 1.8em !important;
}
.cc-window .cc-btn {
  padding: 0.2em 0.6em !important;
}

@media screen and (max-width: 768px) {
  .cc-window.cc-floating {
    padding: 0.8em !important;
  }
  .cc-window .cc-message {
    margin-bottom: 0.5em;
  }
  .cc-window.cc-floating .cc-compliance {
    font-size: 1.1em !important;
    padding: 0.5em !important;
  }
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-box {
  width: 100%;
  max-width: 1100px;
  padding: 10px;
  background-color: white !important;
}
.flex-box-widget {
  margin-right: 0px;
}
.main-container {
  background-color: #ffffff;
  margin: 0;
  padding: 15px 0 0 0;
  min-height: 350px;
}

.top-banner {
  width: 100%;
  max-height: 180px;
  border-radius: 6px;
  padding-top: 25%;
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}
.app-version,
.company {
  font-size: 0.65rem;
  color: #999;
  margin-top: 5px;
  margin-left: 3px;
  margin-right: 3px;
  text-align: center;
}
.footer {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 0.8rem;
}
.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}
.mb-1 {
  margin-bottom: 0.3rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-15 {
  margin-bottom: 15px;
}

.mt-15 {
  margin-top: 15px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mt-5 {
  margin-top: 5px;
}

.text-center {
  text-align: center;
}

.card-powered {
  background-color: #f5f5f5;
}
.card-powered-content {
  text-align: center;
  font-size: 0.6rem;
}

.no-radius-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.no-border-top {
  border-top: 0 !important;
}
.no-border-bottom {
  border-bottom: 0 !important;
}
.no-radius-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.hidden-mobile {
  display: block;
}
@media only screen and (max-width: 768px) {
  .hidden-mobile {
    display: none;
  }
}

.main-container-card {
  /* padding-top: 0px; */
}

@media only screen and (max-width: 768px) {
  .main-container-card {
    border: 0px !important;
  }
  .main-container-card .ant-card-body {
    padding: 5px !important;
  }
}

.ant-card-bordered {
  border: 1px solid #dfdfdf;
}

.span-link {
  display: inline-block;
  cursor: pointer;
  color: #7ba9d6;
  text-decoration: underline;
}
.as-link {
  cursor: pointer;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  justify-content: center;
}

.offline-payment-info {
  margin-top: 25px;
  padding: 10px;
}

.offline-payment-info .content {
  font-size: 0.8rem;
  font-weight: 400;
  font-style: italic;
}

.product-title-container {
  display: inline-flex;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
}

.product-title-container h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.product-title-container img {
  margin-right: 10px;
}

.product-title {
  margin-bottom: 0.5em !important ;
  color: #464646 !important;
  font-weight: 600 !important;
  font-size: 1.5rem !important;
  line-height: 1.35 !important;
}

.product-title-long {
  margin-bottom: 0px !important ;
  color: #464646 !important;
  font-weight: 600 !important;
  font-size: 1.15rem !important;
  line-height: 1.35 !important;
}
.product-title,
.product-title-long {
  align-self: center;
}
.product-image {
  cursor: pointer;
  border-radius: 6px;
}

.product-description {
  font-size: 0.8rem;
  color: #999;
  margin-top: 5px;
  text-align: left;
  font-style: italic;
}
.producer-container {
  display: flex;
  justify-content: start;
  padding-top: 0px;
}
.producer-container .producer {
  /* // */
}
.producer-container .producer-name {
  font-size: 0.8rem;
  color: rgb(88 88 88);
  margin-left: 10px;
  text-align: left;
  vertical-align: top;
}

/* .producer-container .producer-contact {
  font-size: 0.7rem;
  color: rgb(75, 75, 75);
  margin-left: 15px;
  text-align: left;
  vertical-align: top;
} */
.pricing-container {
  padding-top: 15px;
}
.external-management-info {
  font-size: 0.7rem;
  color: #999;
  margin-top: 0px;
  font-weight: 400;
  text-align: right;
  font-style: italic;
}
.pricing-container .price {
  display: flex;
  justify-content: end;
  margin-bottom: 0px;
}

.pricing-container .price-selector {
  padding-bottom: 8px;
}
.pricing-container .n_intallments {
  font-size: 1rem;
  margin-left: 10px;
  margin-top: 0px;
  color: rgb(107, 107, 107);
}

.pricing-container .price-select {
  width: 100%;
}

.pricing-container .installments_disclaimer {
  padding-top: 0px;
  text-align: right;
}
.pricing-container .installments_disclaimer .text {
  font-size: 0.65rem;
  color: #999;

  font-style: italic;
}

.pricing-container .pricing-main,
.pricing-container .pricing-multiples {
  display: block;

  font-size: 0.75rem;
  font-weight: 600;
}

.pricing-container .pricing-card {
  padding: 5px;
  padding-bottom: 0px;
  margin: 0px;
  /* margin-bottom: 5px; */
  background-color: #fcfcfc;
}

.pricing-container .pricing-card .ant-card-body {
  padding: 0px;
  margin: 5px;
}

.pricing-container .grand-pricing .grand-pricing-card {
  padding: 5px;
  padding-bottom: 0px;
  margin: 0px;
  /* margin-bottom: 5px; */
  border-width: 0px;
  /* background-color: #f7fffb; */
}
.pricing-container .grand-pricing .grand-pricing-card .ant-card-body {
  padding: 0px;
  margin: 5px;
}

.pricing-container .pricing-multiples .name,
.pricing-container .pricing-main .name {
  font-size: 0.8rem;
  margin-bottom: 0px;
  margin-right: 4px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
}

.pricing-container .pricing-main .name {
  /* padding-top: 15px; */
}

.pricing-container .pricing-main .product-price {
  display: flex;
  justify-content: space-between;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
}

.pricing-container .pricing-multiples .product-price {
  display: flex;
  justify-content: space-between;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pricing-container .price-details {
  /* // */
}
.pricing-offer-name {
  margin-bottom: 5px;
  text-align: right;
}

.pricing-container .price-detail {
  display: flex;
  justify-content: space-between;
  font-size: 0.6rem;
  font-weight: 600;
}
.pricing-container .price-detail-title {
  text-align: left;

  color: #999;
}
.pricing-container .price-detail-value {
  text-align: right;
  color: #999;
}

.pricing-container .pricing-main .price,
.pricing-container .pricing-multiples .price {
  font-size: 1rem;
}

/* .pricing-container .pricing-main .price h2 {
  font-size: 1.4rem;
} */

@media only screen and (max-width: 992px) {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 4px 14px;
  }
  .ant-select-item {
    min-height: 55px;
    line-height: 45px;
  }
}
@media only screen and (max-width: 768px) {
  /* .flex-box-widget {
    margin-right: 16px !important;
  } */

  .steps-container .ant-card-body {
    padding: 10px;
  }
  .legal-container .legal {
    font-size: 00.65rem;
    color: #999;
  }

  .legal-container .legal {
    font-size: 00.65rem;
    color: #999;
  }

  .pricing-container {
    padding-top: 5px;
  }

  .pricing-container .pricing-multiples .name,
  .pricing-container .pricing-main .name {
    font-size: 1rem;
    margin-bottom: 0px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pricing-container .pricing-main .name {
    padding-top: 5px;
  }
  /* .pricing-card {
    border: 0px;
  } */
  .pricing-card > .ant-card-body {
    padding: 5px;
  }

  .pricing-container .pricing-main .price,
  .pricing-container .pricing-multiples .price {
    font-size: 0.8rem;
    align-self: center;
    line-height: 1rem;
  }
  /* .pricing-container .pricing-main .price h2 {
    font-size: 1rem;
  } */
  .pricing-container .price-details .product-price {
    display: flex;
    justify-content: space-between;
  }
}

/**
  *
  * Grand pricing
*/
.pricing-container .grand-price-details {
  /* // */
}

.pricing-container .grand-price-detail {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 600;
}
.pricing-container .grand-price-detail-title {
  text-align: left;

  color: #575757;
}
.pricing-container .grand-price-detail-value {
  text-align: right;
  color: #575757;
}

.pricing-container .grand-price-detail-discount {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 600;
}
.pricing-container .grand-price-detail-title-discount {
  text-align: left;

  color: #21ba45;
}
.pricing-container .grand-price-detail-value-discount {
  text-align: right;
  color: #21ba45;
}

.pricing-container .grand-pricing .price {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  font-weight: 600;
}
.pricing-container .grand-pricing .price .small {
  font-size: 0.7rem;
  font-weight: 600;
  font-style: italic;
}

.pricing-container .grand-pricing .installments-info {
  font-size: 0.7rem;
  font-weight: 400;
  text-align: right;
  color: #999;
}

.pricing-container .grand-pricing .installments-info .frequency {
  font-size: 0.65rem;
  font-weight: 400;
  text-align: right;
  color: #999;
}

/*******************************/
.flex-box-small {
  width: 100%;
  max-width: 800px;
  padding: 10px;
  background-color: white !important;
}

.waiting-payment-main-container {
  background-color: "#fff";
  margin: 0;
  padding: 15px 0 0 0;
  min-height: 350px;
}

.waiting-payment-container {
  padding-top: 0px;
  min-height: 40vh;
}

.waiting-payment-container .payment-status {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}
.waiting-payment-container .order-details {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.waiting-payment-container .alerts {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}
.waiting-payment-container div.ant-typography,
.ant-typography p {
  margin-bottom: 0.1rem !important;
}

.waiting-payment-container .title {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  margin-top: 10px;
}

.confirm-payment-container-card {
  border: 0px !important;
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgb(0 0 0 / 11%), 0 3px 6px 0 rgb(0 0 0 / 7%), 0 5px 12px 4px rgb(0 0 0 / 4%);
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .product-title-long {
    margin-bottom: 0px !important;
    color: #464646 !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
    line-height: 1.3 !important;
  }

  .pricing-container .grand-pricing .price {
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .confirm-payment-container-card {
    border: 0px !important;
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 11%), 0 3px 6px 0 rgb(0 0 0 / 7%), 0 5px 12px 4px rgb(0 0 0 / 4%);
  }

  .confirm-payment-container-card .ant-card-body {
    padding: 10px !important;
  }

  .waiting-payment-container .payment-status {
    padding-left: 5px;
    padding-right: 5px;
  }
  .waiting-payment-container .order-details {
    padding-left: 5px;
    padding-right: 5px;
  }
}

/*********************/

.authentication-container form .ant-row {
  display: grid;
}
.installments-payment-container form .ant-row {
  display: grid;
}
.installments-payment-container h3 {
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .installments-payment-container {
    padding-left: 5px;
    padding-right: 5px;
  }
  .installments-payment-container h3 {
    text-align: center;
  }
}
/* .ant-form-item-with-help .ant-form-item-explain {
  height: 60px !important;
} */

/* .main-container-card { */
/* padding-top: 0px; */
/* } */
.main-container-card > .ant-card-body {
  padding-top: 15px;
}

/*
*
*
*
 IDENTIFICATION FIELDS
*
*
* 
*/

.identification-container-normal input {
  font-size: 1.2rem;
  padding: 6px;
  font-weight: 600;
}

.identification-container-normal .react-tel-input input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.identification-container-normal .react-tel-input input {
  font-weight: 500;
}
.identification-container-normal .react-tel-input .form-control {
  font-size: 1.2rem;
  padding: 6px;
  line-height: 1.5715;
  height: auto !important;
  width: 100% !important;
  padding-left: 50px !important;
  order: 1px solid #d9d9d9;
}

.identification-container-normal .ant-form-item-with-help .ant-form-item-explain {
  height: 40px !important;
}

.identification-container-normal button.coupon-button {
  font-size: 1.1rem;
  font-weight: 600;
  height: auto;
  padding: 8px 20px;
  border-radius: 0.28571429rem;
}

.identification-container-normal button.coupon-button-remove {
  font-size: 1rem;
  font-weight: 400;
  height: auto;
  padding: 8px 20px;
  border-radius: 0.28571429rem;
}

.identification-container-normal .submit-button {
  /* background-color: #21ba45 !important;
  background: #21ba45 !important;
  border-color: #44bd60 !important; */
  font-size: 1.3rem;
  font-weight: 600;
  height: auto;
  padding: 8px 20px;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
  box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
}
/* .identification-container-normal .submit-button::selection {
  color: #fff;
  background: #21ba45 !important;
}
  */

.identification-container-normal .submit-button:hover {
  /* background-color: #16ab39;
  border-color: #44bd60; */
}
.options-container .submit-button-smaller,
.identification-container-normal .submit-button-smaller {
  /* background-color: #21ba45;
  border-color: #44bd60; */
  font-size: 1rem;
  font-weight: 600;
  height: auto;
  padding: 8px 15px;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
  box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
}
.options-container .submit-button-smaller:hover,
.identification-container-normal .submit-button-smaller:hover {
  /* background-color: #16ab39;
  border-color: #44bd60; */
}

.identification-container-normal {
  padding-top: 0px;
}

.identification-container-normal form .ant-row {
  display: grid;
}

.identification-container-normal .step-title {
  text-align: center;
  color: #999;
}

/**************************************************************************/
.identification-container-small .ant-form-item {
  margin-bottom: 15px !important;
}

.identification-container-small .form-item-coupon {
  line-height: 15px;
}

.identification-container-small .ant-row .ant-form-item-label {
  padding-bottom: 2px !important;
}

.identification-container-small input {
  font-size: 0.8rem;
  padding: 7px;
  font-weight: 600;
}

.identification-container-small .ant-form-item-with-help .ant-form-item-explain {
  height: 20px !important;
}

.identification-container-small button.coupon-button {
  font-size: 0.85rem;
  font-weight: 600;
  height: auto;
  padding: 6px 20px;
  border-radius: 0.28571429rem;
}

.identification-container-small button.coupon-button-remove {
  font-size: 0.85rem;
  font-weight: 400;
  height: auto;
  padding: 6px 20px;
  border-radius: 0.28571429rem;
}

.identification-container-small .submit-button {
  background-color: #21ba45;
  border-color: #44bd60;
  font-size: 1rem;
  font-weight: 600;
  height: auto;
  padding: 8px 20px;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
  box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
}

.identification-container-small .submit-button:hover {
  background-color: #16ab39;
  border-color: #44bd60;
}

.identification-container-small {
  padding-top: 0px;
}

.identification-container-small form .ant-row {
  display: flex;
}

.identification-container-small .step-title {
  text-align: center;
  color: #999;
}
.identification-container-small .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 25px !important;
  padding: 0px 10px !important;
}
.identification-container-small .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 25px !important;
  font-size: 12px !important;
}
/* END OF IDENTIFICATION FIELDS */

.legal-container {
  display: block;
}
.legal-container .legal {
  font-size: 00.65rem;
  color: #999;
}

.purchase-terms-container {
  font-size: 0.9rem;
}

.options-container {
  /* padding-top: 25px; */
}

.billing-form .react-tel-input input {
  font-weight: 400 !important;
}
.billing-form .react-tel-input input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none;
}

.billing-form .react-tel-input .form-control {
  padding: 6px;
  font-size: 16px !important;
  padding-left: 50px !important;
  border: 1px solid #d9d9d9;
}

.options-container .billing-data-notice,
.identification-container-normal .billing-data-notice,
.identification-container-small .billing-data-notice {
  padding-top: 4px;
  font-size: 0.975rem;
  color: #999;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .options-container .billing-data-notice,
  .identification-container-normal .billing-data-notice,
  .identification-container-small .billing-data-notice {
    padding-top: 0px;
    font-size: 0.675rem;
    color: #999;
    text-align: center;
  }
  .purchase-terms-container {
    text-align: justify;
  }
}
.options-container .submit-button {
  white-space: break-spaces;
  background-color: #21ba45;
  border-color: #44bd60;
  font-size: 1.3rem;
  font-weight: 600;
  height: auto;
  padding: 10px 8px;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
  box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
}

.options-container .submit-button:hover {
  background-color: #16ab39;
  border-color: #44bd60;
}

.options-container .submit-button:disabled {
  background-color: #aaaaaa;
  border-color: #adadad;
}

.options-container .price-option-container {
  /* padding-top: 25px; */
}
.options-container .price-option-container .card-group {
  padding-top: 10px;
  padding-bottom: 10px;
}

.options-container .price-option-container .card-group .ant-card-head-title,
.options-container .payment-method-container .card-group .ant-card-head-title {
  font-size: 1.1rem;
  font-weight: 600;
  padding-bottom: 18px;
}

.options-container .price-option-container .card-group .card {
  /* // */
}

.options-container .price-option-container .card-group .card-option-selected {
  padding-top: 7px;
}

.options-container .price-option-container .card-group .card-selected {
  background-color: #e0ffd1;
  border: 1px solid #baeba2;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.08),
    0 5px 12px 4px rgba(0, 0, 0, 0.05);
}

.options-container .price-option-container .card-group .card .card-name {
  font-size: 1rem;
  font-weight: 600;
}
.options-container .price-option-container .card-group .card .card-price {
  font-size: 1rem;
}

.options-container .payment-method-container {
  /* padding-top: 25px; */
}
.options-container .payment-method-container .card-group {
  padding-top: 10px;
  padding-bottom: 10px;
}

.options-container .payment-method-container .method-card {
  margin-bottom: 10px;
}

.options-container .payment-method-container .card-selected {
  background-color: #f1ffea;
  border: 1px solid #95c37f;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.08),
    0 5px 12px 4px rgba(0, 0, 0, 0.05);
}

.options-container .payment-method-container .card-warning {
  background-color: #ffd1d1;
  border: 1px solid #eba2a2;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.08),
    0 5px 12px 4px rgba(0, 0, 0, 0.05);
}

.options-container .payment-method-container .card .card-name,
.options-container .payment-method-container .card-selected .card-name {
  font-size: 0.7rem;
  font-weight: 600;
  margin-top: 5px;
}

/* .options-container .payment-method-container .card-image {
  font-size: 1rem;
  filter: grayscale(100%) !important;
  -webkit-filter: grayscale(100%) !important;
}

.options-container .payment-method-container .card-selected .card-image {
  font-size: 1rem;
  filter: none !important;
  -webkit-filter: grayscale(0%) !important;
} */

/* .options-container .selected-item {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}
.options-container .selected-item .right {
  display: flex !important;
  justify-content: right !important;
  align-items: center;
}
.options-container .selected-item h3 {
  margin-bottom: 0px;
}
 */
.steps-container {
  /* // */
}
.steps-container-mobile {
  text-align: center;
  font-size: 0.6rem;
  background-color: #fbfbfb;
}

.steps-container-mobile .ant-steps-vertical {
  flex-direction: row !important;
}
.steps-container-mobile .ant-steps-item-title {
  font-size: 14px;
}

.steps-container-mobile .ant-steps-item-icon > .ant-steps-icon {
  font-size: 14px !important;
}
.steps-container-mobile .ant-steps-item .ant-steps-item-icon {
  margin-right: 4px;
}
.steps-container-mobile .ant-steps-item-icon {
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.steps-container-mobile .ant-steps-item .ant-steps-item-content {
  min-height: 24px !important;
}

.bump-purchased {
  background-color: #fafffc;
  border: 1px solid #caefd3;
}
.bump-purchased-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.bump-purchased-content-title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 5px;
}

.payment-container {
  padding-top: 0px;
}
.payment-container-top {
  border-bottom: dashed 1px #e3e3e3;
  align-items: center;
  padding-bottom: 5px;
}

.payment-container .title {
  /* display: flex;
  justify-content: center; */
  color: #999;
}
.payment-container .payment-icon {
  /* display: flex;
  justify-content: center;
  padding-top: 10px; */
}

.payment-container .title h3 {
  color: #999;
  font-size: 1rem;
  margin-bottom: 0px;
}

@media only screen and (max-width: 768px) {
  .options-container .payment-method-container .method-card {
    margin-bottom: 0px !important;
  }

  .payment-container .title h3 {
    color: #999;
    font-size: 0.9rem;
  }

  .payment-container-top {
    border-bottom: dashed 1px #e3e3e3;
    padding-bottom: 5px;
  }

  .card-option-selected .selected-item h3 {
    font-size: 0.9rem;
  }
  .price-method-image {
    height: 20px;
  }

  .bump-purchased-content-title {
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 5px;
  }
  .bump-purchased-content-sub-title {
    font-size: 13px;
    font-weight: 600;
  }

  .bump-delete-icon {
    padding: 6px 10px;
    border: 1px solid #ff6b72;
    border-radius: 10px;
  }
}
/**
*
* Multibanco
*
*/
.payment-container .multibanco-container {
  padding-top: 30px;
  padding-bottom: 20px;
  min-height: 200px;
}

.payment-container .multibanco-container .payment-data {
  font-size: 1.7rem;
  display: grid;
  justify-content: center;
}

.payment-container .multibanco-container .payment-text {
  font-size: 1.7rem;
  text-align: left;
}

.payment-container .multibanco-container .payment-text-small {
  font-size: 0.8rem;
  text-align: left;
  font-style: italic;
}

.payment-container .multibanco-container .payment-instructions {
  padding-top: 25px;
  font-size: 0.9rem;
  text-align: center;
  color: #999;
}

.payment-container .multibanco-container .payment-loading {
  padding: 20px;
  text-align: center;
}
.payment-container .multibanco-container .payment-loading-alert {
  padding: 10px;
  font-size: 0.9rem;
  font-weight: 600;
}

.payment-container .multibanco-container .loading {
  display: flex;
  justify-content: center;
  min-height: 150px;
}

.payment-container .multibanco-container .error {
  padding-left: 40px;
  padding-right: 40px;
}
.payment-container .multibanco-container .error button {
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .payment-container .multibanco-container .error {
    padding-left: 5px;
    padding-right: 5px;
  }
}

/**
*
* PAYPAL
*
*/
.payment-container .paypal-container {
  padding-top: 30px;
  padding-bottom: 20px;
  min-height: 200px;
}
.payment-container .paypal-container .title {
  padding-top: 0px;
  padding-bottom: 20px;
  color: #464646;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}
.payment-container .paypal-container .button-helper {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  margin-bottom: 10px;
}
.payment-container .paypal-container .loading-blur {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}
.payment-container .paypal-container .payment-error .error {
  margin-bottom: 25px;
  margin-top: 0px;
}
.payment-container .paypal-container .payment-error .error .alerts {
  margin-top: 10px;
  margin-bottom: 10px;
}

.payment-container .paypal-container .payment-loading {
  padding: 20px;
  text-align: center;
}
.payment-container .paypal-container .payment-loading-alert {
  padding: 10px;
  font-size: 0.9rem;
  font-weight: 600;
}

/**
*
* COFIDISPAY
*
*/
.payment-container .cofidispay-container {
  padding-top: 30px;
  padding-bottom: 20px;
  min-height: 200px;
}

.payment-container .cofidispay-container .title {
  padding-top: 0px;
  padding-bottom: 20px;
  color: #464646;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}
@media only screen and (max-width: 768px) {
  .payment-container .cofidispay-container .title {
    padding-top: 0px;
    padding-bottom: 20px;
    color: #464646;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.payment-container .cofidispay-container .payment-loading-title {
  padding-top: 0px;
  padding-bottom: 2px;
  color: #464646;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.payment-container .cofidispay-container .payment-loading-sub-title {
  padding-top: 0px;
  padding-bottom: 20px;
  color: #8c8c8c;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
}

/**
*
* MBWAY
*
*/
.payment-container .mbway-container {
  padding-top: 30px;
  padding-bottom: 20px;
  min-height: 200px;
}

.payment-container .mbway-container .title {
  padding-top: 0px;
  padding-bottom: 20px;
  color: #464646;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}
@media only screen and (max-width: 768px) {
  .payment-container .mbway-container .title {
    padding-top: 0px;
    padding-bottom: 20px;
    color: #464646;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.payment-container .mbway-container .frm-mbway {
  text-align: center;
  display: grid;
  justify-content: center;
}
.payment-container .mbway-container .frm-mbway .ant-input-affix-wrapper {
  background-color: rgba(255, 229, 0, 0.2);
}
.payment-container .mbway-container .frm-mbway .not-valid {
  background-color: rgba(255, 229, 0, 0.2);
}
.payment-container .mbway-container .frm-mbway .is-valid {
  background-color: rgba(33, 186, 69, 0.1);
}

.payment-container .mbway-container .frm-mbway .ant-form-item {
  margin-bottom: 0;
}

.payment-container .mbway-container .frm-mbway input {
  padding: 10px !important;
  background-color: transparent;
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  text-align: center;
  margin-bottom: 0;
}

.payment-container .mbway-container .frm-mbway .ant-form-item-control-input-content {
  text-align: center;

  width: 80% !important;
  max-width: 350px !important;
}
.payment-container .mbway-container .submit-button {
  margin-top: 20px;
}

.payment-container .mbway-container .submit-button {
  white-space: break-spaces;
  background-color: #21ba45;
  color: white;
  border-color: #44bd60;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  height: auto;
  padding: 10px 15px;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
  box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
}

.payment-container .mbway-container .submit-button:hover {
  background-color: #16ab39;
  border-color: #44bd60;
}

.payment-container .mbway-container .submit-button:disabled {
  background-color: #aaaaaa;
  border-color: #adadad;
}

.payment-container .mbway-container .payment-loading-title {
  padding-top: 0px;
  padding-bottom: 2px;
  color: #464646;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.payment-container .mbway-container .payment-loading-sub-title {
  padding-top: 0px;
  padding-bottom: 20px;
  color: #a5a5a5;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
}
.payment-container .mbway-container .payment-loading-image {
  margin-top: 20px;
  text-align: center;
}

.payment-container .mbway-container .payment-loading {
  padding: 20px;
  text-align: center;
}
.payment-container .mbway-container .payment-loading-alert {
  padding: 10px;
  font-size: 0.9rem;
  font-weight: 600;
}
.payment-container .mbway-container .timer {
  font-size: 0.95rem;
  text-align: center;
  font-weight: 600;
}

.payment-container .mbway-container .error {
  padding-left: 40px;
  padding-right: 40px;
}
.payment-container .mbway-container .error button {
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .payment-container .mbway-container .error {
    padding-left: 5px;
    padding-right: 5px;
  }
}

/**
*
* CREDIT CARD
*
*/
.payment-container .credit-card-container {
  padding-top: 30px;
  padding-bottom: 20px;
  min-height: 200px;
}

.payment-container .credit-card-container .title {
  padding-top: 0px;
  padding-bottom: 20px;
  color: #464646;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.payment-container .credit-card-container .submit-button {
  white-space: break-spaces;
  background-color: #21ba45;
  color: white;
  border-color: #44bd60;
  font-size: 1.2rem;
  font-weight: 600;
  height: auto;
  padding: 10px 15px;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
  box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
}

.payment-container .credit-card-container .submit-button:hover {
  background-color: #16ab39;
  border-color: #44bd60;
}

.payment-container .credit-card-container .submit-button:disabled {
  background-color: #aaaaaa;
  border-color: #adadad;
}

.payment-container .credit-card-container .submit-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.payment-container .credit-card-container .payment-error {
  margin-bottom: 25px;
  margin-top: 0px;
}
.payment-container .credit-card-container .error-list {
  margin-top: 0px;
}

.payment-container .credit-card-container .row-card-holder {
  margin-top: 20px;
  margin-bottom: 15px;
}
.payment-container .credit-card-container .card-holder {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}
.payment-container .credit-card-container .error-line {
  font-size: 1rem;
}

.payment-container .credit-card-container .error {
  padding-left: 40px;
  padding-right: 40px;
}
.payment-container .credit-card-container .error button {
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .payment-container .credit-card-container .error {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.StripeElement {
  display: block;
  margin: 0px 0 5px 0 !important;

  padding: 10px 0px;
  font-size: 1rem !important;
  /* font-family: "Source Code Pro", monospace !important; */
  /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;*/
  /* border: 1px solid #d9d9d9; */
  outline: 0;
  border-radius: 4px;
  background: white;
}
.StripeElement-input {
  display: block;

  padding: 8px 14px;
  font-size: 1rem !important;
  /* font-family: "Source Code Pro", monospace !important; */
  /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;*/
  /* border: 1px solid #d9d9d9; */
  outline: 0;
  border-radius: 4px;
  background: white;
}

.StripeElementBlur {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

.StripeElement input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.stripe-form label {
  height: 32px;
  color: #464646;
  font-size: 14px;
}
.stripe-form label::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  content: "*";
}
/* // Media queries breakpoints
// Extra small screen / phone
@screen-xs: 320px;
@screen-xs-min: @screen-xs;

// Small screen / tablet
@screen-sm: 375px;
@screen-sm-min: @screen-sm;

// Medium screen / desktop
@screen-md: 768px;
@screen-md-min: @screen-md;

// Large screen / wide desktop
@screen-lg: 1024px;
@screen-lg-min: @screen-lg;

// Extra large screen / full hd
@screen-xl: 1440px;
@screen-xl-min: @screen-xl;

// Extra extra large screen / large desktop
@screen-xxl: 1600px;
@screen-xxl-min: @screen-xxl;
} */

.authentication-container {
}
.authentication-container .authentication-card {
  padding: 5px;
  margin: 0px;
  margin-bottom: 5px;
  background-color: #fcfcfc;
}
.installments-payment-container .installments-payment-card {
  padding: 5px;
  margin: 0px;
  margin-bottom: 5px;
  background-color: #fcfcfc;
}

.authentication-container .authentication-card input {
  font-size: 1.2rem;
  padding: 6px;
  font-weight: 600;
}
.authentication-container .authentication-container-row {
  display: flex;
  justify-content: center;
}

.installments-payment-container .installments-payment-container-row {
  display: flex;
  justify-content: center;
}
.installments-payment-container .installments-payment-container-row .ant-col {
  max-width: 1000px;
}

.authentication-container .timer {
  font-size: 0.6rem;
  text-align: center;
  font-weight: 600;
}
.billing-info .timer {
  font-size: 0.6rem;
  text-align: center;
  font-weight: 600;
}

.tallbtn {
  height: auto;
  padding: 10px 15px;
  font-size: 1.2rem;
}

.ant-dropdown-menu {
  max-height: 250px !important;
  width: 200px !important;
  overflow: auto !important;
}
.flag-sprite {
  width: 21px;
  height: 14px;
  text-indent: -99999px;
  display: inline-flex;
  align-items: center;
  background-size: contain;
}

.flag-sprite-small {
  width: 15px;
  height: 10px;
  text-indent: -99999px;
  display: inline-flex;
  align-items: center;
  background-size: contain;
}

/* Stupid fix... Tab doesn't hide on change.... */
.ant-tabs-tabpane-hidden {
  display: none !important;
}

.fixedFormItem .ant-form-item-label {
  padding-bottom: 4px;
}

.fixedFormItem .ant-form-item-label > label {
  height: 22px;
}
.ant-layout-header {
  background-color: #fff !important;
}
.ant-card,
.ant-btn-primary,
.ant-btn-default,
.ant-btn-alert {
  border-radius: 0.28571429rem;
}

.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}

.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #52c41a;
  animation-name: diffZoomIn1 !important;
}

.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #ff4d4f;
  animation-name: diffZoomIn2 !important;
}

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: #fff;
  border-color: #ff4d4f;
}

.refundRequestForm .ant-select {
  width: 100%;
}

.coupon-active {
  border-color: rgb(82 196 26 / 50%) !important;
}

.no-card {
  border: 0px !important;
}

.clickable {
  cursor: pointer;
}

.clickable_hover {
  cursor: pointer;
  color: #1890ff !important;
}

.clickable_hover:hover {
  cursor: pointer;
  color: #3e79f7 !important;
}
.wheelchair-icon svg {
  color: #ffffff !important;
  width: 24px;
}

.wheelchair-icon-small svg {
  color: #ffffff !important;
  width: 16px;
}

.wheelchair-button {
  height: 50px !important;
  background-color: #ee6c4d !important;
  color: #ffffff !important;
  border-color: #cf5437 !important;
}

.wheelchair-button-small {
  height: 35px !important;
  background-color: #ee6c4d !important;
  color: #ffffff !important;
  border-color: #cf5437 !important;
  padding: 0px 15px !important;
}

.wheelchair-button:hover {
  height: 50px !important;
  background-color: #ef846b !important;
  color: #ffffff !important;
  border-color: #ee6c4d !important;
}

.wheelchair-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  font-weight: 600;
}

.wheelchair-content-small {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}

.m-0 {
  margin: 0px !important;
}
.p-0 {
  padding: 0px !important;
}
.mr-0 {
  margin-right: 0px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.smallTag {
  font-size: 10px !important;
  padding: 0px 5px !important;
  margin: 0px 5px 0px 8px !important;
  line-height: 20px !important;
}

.smallTag span.anticon {
  font-size: 12px;
}

.smallTagNoMargin {
  font-size: 10px;
  padding: 0px 5px;
  margin: 0px 2px 0px 2px;
  line-height: 20px;
}

.nextButtonFixed {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  box-shadow: 0px -5px 10px -5px rgb(0 0 0 / 30%);
  background: rgba(255, 255, 255, 0.97);
  padding: 12px;
  border-top: 1px solid #dedede;
  z-index: 999;
  text-align: center;
}

.purchase-terms-modal {
  top: 20px !important;
}

@media screen and (max-width: 768px) {
  .alert-duplicates-sales .ant-alert-message {
    font-size: 12px;
  }
  .alert-duplicates-sales .ant-alert-description {
    display: none;
    font-size: 11px;
    line-height: 14px;
  }

  .purchase-terms-modal .ant-modal-body {
    padding: 6px;
  }
}

.add-to-calendar-container {
  border-radius: 0.25rem;
  border: 1px solid #e6e9ed;
  display: grid;
  font-weight: 600;
}
.add-to-calendar-container a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: #191c1f;
}

.add-to-calendar-container a:not(:last-child) {
  border-bottom: 1px solid #e6e9ed;
}

.add-to-calendar-container a div {
  display: flex;
  align-items: center;
}

.add-to-calendar-container a div span {
  font-size: 18px;
}

.add-to-calendar-icon {
  width: 24px;
  margin-right: 10px;
}
.add-to-calendar-icon-office365 {
  fill: #ea3e23;
}
.add-to-calendar-icon-yahoo {
  fill: #5f01d1;
}

.event-schedule-description {
  border: 1px solid #e6e9ed;
  padding: 8px;
  border-radius: 0.25rem;
}
.event-schedule-description-text {
  padding-left: 5px;
}

@media screen and (max-width: 768px) {
  .add-to-calendar-container a {
    padding: 15px;
  }
  .add-to-calendar-container a div span {
    font-size: 16px;
  }
  .add-to-calendar-icon {
    width: 20px;
    margin-right: 8px;
  }
  .event-schedule-description {
    margin-bottom: 10px;
  }
}

.qty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}

.qty-container button {
  margin-left: 10px;
  margin-right: 10px;
}

.qty-container .qty-indicator {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.5rem;
  font-weight: 600;
}

/**********************************************************************************************
***********************************************************************************************
***********************************************************************************************
*
*
*
*
* Tickets
*
*
*
*
***********************************************************************************************
***********************************************************************************************
***********************************************************************************************/

.attendee-ticket-container {
  height: 100vh;
  display: grid;
  color: black;
  font-size: 14px;
  letter-spacing: 0.1em;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.attendee-ticket-container .ticket {
  border-radius: 6px;
  height: 250px;
  width: 720px;
  margin: auto;
  display: flex;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 6px, rgba(0, 0, 0, 0.22) 0px 6px 15px;
}

.attendee-ticket-container .left {
  display: flex;
  width: 100%;
}

.attendee-ticket-container .image {
  height: auto;
  background-size: cover;
  width: 250px;
  /* background-image: url("https://salespark.fra1.cdn.digitaloceanspaces.com/products/pictures/dev--xbf3zksldy9hcsn"); */
  /* background-size: contain; */
  opacity: 0.85;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.attendee-ticket-container .admit-one {
  position: absolute;
  color: darkgray;
  height: 250px;
  padding: 0;
  margin: 2px;
  letter-spacing: 0.15em;
  display: flex;
  text-align: center;
  justify-content: space-around;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  font-size: 40%;
}

.attendee-ticket-container .admit-one span:nth-child(2) {
  color: white;
  font-weight: 700;
}

.attendee-ticket-container .left .ticket-code {
  height: 250px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 2px;
  font-size: 10px;
  text-shadow: #fff 0px 0 3px;
}

.attendee-ticket-container .ticket-info {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.attendee-ticket-container .date {
  /* border-top: 1px solid gray;
  border-bottom: 1px solid gray; */
  padding: 5px 0;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.attendee-ticket-container .date span {
  width: 100px;
}

.attendee-ticket-container .date span:first-child {
  text-align: left;
}

.attendee-ticket-container .date span:last-child {
  text-align: right;
}

.attendee-ticket-container .show-name {
  font-size: 16px;
  /*nt-family: "Nanum Pen Script", cursive;*/
  /* color: #d83565; */
}

.attendee-ticket-container .show-name h1 {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #5a5a5a;
  margin: 0;
}

.attendee-ticket-container .show-name h2 {
  font-size: 16px;
  margin: 0;
  font-weight: 700;
  margin-top: 6px;
}
.right-info-container .show-name {
  font-size: 10px;
  text-align: center;
  line-height: 12px;
}

.attendee-ticket-container .show-name h3 {
  font-size: 12px;
  margin: 0;
  font-weight: 700;
}

.attendee-ticket-container .time {
  padding: 8px 0;
  color: #2e2e2e;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0px;
  font-weight: 700;
}

.attendee-ticket-container .time p {
  margin: 0;
}

.attendee-ticket-container .time span {
  font-weight: 400;
  color: gray;
}

.attendee-ticket-container .left .time {
  font-size: 13px;
}

.attendee-ticket-container .location {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: 8px;
  border-top: 1px dashed #d0d0d0;
  font-size: 12px;
  margin-bottom: 0px;
}

.attendee-ticket-container .location .separator {
  font-size: 20px;
}

.attendee-ticket-container .right {
  width: 150px;
  border-left: 1px dashed #404040;
}

.attendee-ticket-container .right .admit-one {
  color: darkgray;
}

.attendee-ticket-container .right .admit-one span:nth-child(2) {
  color: gray;
}

.attendee-ticket-container .right .right-info-container {
  height: 250px;
  padding: 10px 10px 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.attendee-ticket-container .right .show-name h1 {
  font-size: 18px;
}

.attendee-ticket-container .barcode {
  height: 100px;
}

.attendee-ticket-container .barcode img {
  height: 100%;
}

.attendee-ticket-container .right .ticket-code {
  color: gray;
  font-size: 6px;
}

/*****************************************************************************
******************************************************************************
******************************************************************************
******************************************************************************/
.react-international-phone-input-container .react-international-phone-input {
  background-color: #fff !important;
  background-image: none !important;
  border: 1px solid #d9d9d9 !important;
  color: #000000a6 !important;
  transition: all 0.3s !important;
}
.identification-container-normal .react-international-phone-input-container {
  width: 100%;
}
.identification-container-normal .react-international-phone-input-container input,
.identification-container-normal .react-international-phone-input-container button {
  height: 42px !important;
  padding: 4px 5px;
}

.identification-container-normal .react-international-phone-input-container .react-international-phone-input {
  width: 100%;
  height: 42px !important;
  font-size: 1.2rem !important;
  padding: 6px !important;
  font-weight: 600 !important;
  line-height: 1.5715 !important;
}
.identification-container-normal .react-international-phone-input-container:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.identification-container-normal
  .react-international-phone-input-container
  .react-international-phone-input::placeholder {
  color: #bfbfbf !important;
  user-select: none;
}

.identification-container-small
  .react-international-phone-input-container
  .react-international-phone-input::placeholder {
  color: #bfbfbf !important;
  user-select: none;
}

.identification-container-small .react-international-phone-input-container,
.identification-container-small .react-international-phone-input-container .react-international-phone-input {
  width: 100%;
}

.billing-info .react-international-phone-input-container .react-international-phone-input {
  font-size: 1rem;
  font-weight: 400;
}

.billing-info .react-international-phone-input-container .react-international-phone-input::placeholder {
  color: #bfbfbf !important;
  user-select: none;
}

.ant-form-item-has-error .internacional-phone-input button {
  border-left-color: #ff4d4f !important;
  border-top-color: #ff4d4f !important;
  border-bottom-color: #ff4d4f !important;
}
.ant-form-item-has-error .internacional-phone-input input {
  border-right-color: #ff4d4f !important;
  border-top-color: #ff4d4f !important;
  border-bottom-color: #ff4d4f !important;
}

/*****************************************************************************
******************************************************************************
******************************************************************************
******************************************************************************/

.cofidispay-container {
  margin-top: 25px;
}
.cofidispay-container input {
  font-size: 1rem !important;
  height: 40px !important;
}
.cofidispay-container .ant-select {
  font-size: 1rem !important;
}
.cofidispay-container .ant-select-selector {
  height: 40px !important;
  align-items: center;
}

.cofidispay-container button {
  height: 45px !important;
  font-size: 1rem !important;
}
